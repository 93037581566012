.bg-dark-blue {
  background-color: #252531 !important
}

.bg-dark {
  background-color: black !important;
}

NavLink {
  text-decoration: none !important;
}